import React, { useEffect, useRef } from "react";

const Wave = () => {
  const wave1Ref = useRef(null);
  const wave2Ref = useRef(null);
  const wave3Ref = useRef(null);
  const wave4Ref = useRef(null);

  useEffect(() => {
    let scrollSpeed = 100;
    let windowScrollY = window.scrollY > 0 ? window.scrollY : 50;
    let isScrolling;

    const updateScrollPosition = () => {
      windowScrollY = 300;

      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        windowScrollY = 100;
      }, 200);
    };

    const animateWaves = () => {
      scrollSpeed += windowScrollY * 0.01;

      if (wave1Ref.current)
        wave1Ref.current.style.backgroundPositionX = scrollSpeed * 3 + "px";
      if (wave2Ref.current)
        wave2Ref.current.style.backgroundPositionX = scrollSpeed * -2 + "px";
      if (wave3Ref.current)
        wave3Ref.current.style.backgroundPositionX = scrollSpeed * 1 + "px";
      if (wave4Ref.current)
        wave4Ref.current.style.backgroundPositionX = scrollSpeed * -1 + "px";

      requestAnimationFrame(animateWaves);
    };

    window.addEventListener("scroll", updateScrollPosition);
    animateWaves();

    return () => {
      window.removeEventListener("scroll", updateScrollPosition);
      window.cancelAnimationFrame(animateWaves);
    };
  }, []);

  return (
    <section className="relative w-full overflow-hidden m-0 p-0 ">
      <div className="flex  items-end h-[250px] w-screen m-0 p-0">
        <div
          ref={wave1Ref}
          className="wave1 h-[120px] md:h-40 w-full absolute "
          style={{
            zIndex: 10000,
            background: `url('/wave/wave4.png')`,
            // backgroundSize: "contain",
            // backgroundPosition: "bottom",
            animation: "wave 10s linear infinite",
          }}
        ></div>
        {/* Uncomment these if you are using them */}
        <div
          ref={wave2Ref}
          className="wave2 h-[160px] md:h-44 w-full absolute  opacity-60"
          style={{
            zIndex: 9999,
            background: `url('/wave/wave4.png')`,
            animation: "wave 10s linear infinite",
          }}
        ></div>
        <div
          ref={wave3Ref}
          className="wave3 h-[200px] md:h-48 w-full absolute  opacity-60"
          style={{
            zIndex: 9998,
            background: `url('/wave/wave4.png')`,
            animation: "wave 10s linear infinite",
          }}
        ></div>
        <div
          ref={wave4Ref}
          className="wave4 h-[300px] md:h-56 w-full absolute  opacity-60"
          style={{
            zIndex: 9997,
            background: `url('/wave/wave4.png')`,
            animation: "wave 10s linear infinite",
          }}
        ></div>
      </div>
    </section>
  );
};

export default Wave;
