import React from "react";

export default function Contact() {
  return (
    <div className="flex justify-center bg-primary text-white w-full py-10">
      <div className=" w-mobile md:w-desktop  ">
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-[1.8rem] md:text-[2.5rem] font-bold pb-5">
            Get In Touch
            <span className="block bg-white h-1 w-full mt-[1px]"></span>
          </h1>
        </div>
        <div className=" flex flex-col md:flex-row  gap-4">
          <div className=" w-full md:w-1/2 text-gray-400">
            <p className="mb-8">
              I'd love to hear from you! Whether you have a question or just
              want to say hi, feel free to reach out.
            </p>
            <div>
              <p>
                Email:{" "}
                <a
                  href="mailto:grgayush068@gmail.com"
                  className="text-secondary underline"
                >
                  grgayush068@gmail.com
                </a>
              </p>
            </div>
          </div>
          <div className=" w-full  md:w-1/2">
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full p-3 rounded-lg bg-primary border border-gray-700 focus:border-secondary outline-none text-white"
                  required
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  className="w-full p-3 rounded-lg bg-primary border border-gray-700 focus:border-secondary outline-none text-white"
                  required
                />
              </div>
              <textarea
                rows="4"
                placeholder="Your Message"
                className="w-full p-3 rounded-lg bg-primary border border-gray-700 focus:border-secondary outline-none text-white"
                required
              ></textarea>
              <button
                type="submit"
                className="w-full py-3 bg-primary border-2 border-gray-700 hover:border-white hover:bg-white hover:text-gray-800 rounded-lg font-semibold text-white transition duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    // <div className="bg-gray-900 text-white py-12 px-6">
    //   <div className="max-w-2xl mx-auto text-center">
    //     <h2 className="text-3xl md:text-4xl font-bold mb-6">Get in Touch</h2>
    //     <p className="mb-8 text-gray-400">
    //       I'd love to hear from you! Whether you have a question or just want to
    //       say hi, feel free to reach out.
    //     </p>
    //   </div>

    //   <div className=" mx-auto grid grid-cols-3 gap-5">
    //     <div className="">
    //       ayush
    //     </div>
    //     <form className="space-y-6">
    //       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    //         <input
    //           type="text"
    //           placeholder="Your Name"
    //           className="w-full p-3 rounded-lg bg-primary border border-gray-700 focus:border-white outline-none text-white"
    //         />
    //         <input
    //           type="email"
    //           placeholder="Your Email"
    //           className="w-full p-3 rounded-lg bg-primary border border-gray-700 focus:border-white outline-none text-white"
    //         />
    //       </div>
    //       <textarea
    //         rows="4"
    //         placeholder="Your Message"
    //         className="w-full p-3 rounded-lg bg-primary border border-gray-700 focus:border-white outline-none text-white"
    //       ></textarea>
    //       <button
    //         type="submit"
    //         className="w-full py-3 bg-indigo-500 hover:bg-indigo-600 rounded-lg font-semibold text-white transition duration-300"
    //       >
    //         Send Message
    //       </button>
    //     </form>
    //   </div>

    //   <div className="max-w-2xl mx-auto mt-10 text-center">
    //     <p className="text-gray-400">Or reach out directly at:</p>
    //     <a href="mailto:youremail@example.com" className="text-indigo-400">
    //       youremail@example.com
    //     </a>
    //   </div>
    // </div>
  );
}
