import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";import { FiGithub } from "react-icons/fi";

const Header = () => {
  const [bg, setBG] = useState("white");
  const [text, setText] = useState("black");

  const [bgColor, setBgColor] = useState("black");
  const [textColor, setTextColor] = useState("black");
  const [hoverColor, setHoverColor] = useState("white");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight * 0.7;

      if (scrollPosition > threshold) {
        setBG("black");
        setText("white");
        console.log("bg", bg);
        console.log("text", text);

        setTextColor("white");
        setBgColor("white");
        setHoverColor("black");
      } else {
        setBG("white");
        setText("black");

        console.log("bg", bg);
        console.log("text", text);

        setTextColor("black");
        setBgColor("black");
        setHoverColor("white");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Debugging state changes
  useEffect(() => {
    console.log("Updated State:");
  }, [bgColor, textColor, hoverColor]); // Listen for changes in these states

  const menuItems = [
    { icon: <FaFacebookF />, id: "facebook" },
    { icon: <FaLinkedinIn />, id: "linkedin" },
    { icon: <FaXTwitter />, id: "twitter" },
    { icon: <FiGithub  />, id: "github" },
  ];

  return (
    <motion.div
      initial={{ y: -200 }}
      animate={{ y: 0 }}
      exit={{ y: -200 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      className={`header z-[999999] text-${textColor} sticky top-0 bg-opacity-50 backdrop-filter backdrop-blur-sm flex justify-center items-center pt-3`}
    >
      <AnimatePresence>
        <div className="w-mobile md:w-desktop">
          <header className="flex justify-between items-center p-2">
            <h1 className="text-4xl font-extrabold">삶</h1>

            
            <ul className="flex gap-2 md:gap-4 items-center">
            {menuItems.map(({ icon, id }) => (
              <li
                className={`group border p-1 rounded-md h-8 md:h-10 aspect-square flex items-center justify-center ease-out duration-200 transform hover:scale-125 ${
                  bg === "white"
                    ? "border-black hover:bg-black hover:text-white"
                    : "border-white hover:bg-white hover:text-black"
                }`}
              >
                {React.cloneElement(icon, {
                    className: `text-[1.2rem] md:text-[1.5rem] ${
                    bg === "white"
                      ? "group-hover:text-white"
                      : "group-hover:text-black"
                  }`,
                  })}
                
              </li>
               ))}
              <li
                className={`group border p-1 rounded-md h-8 md:h-10 aspect-square flex flex-col justify-center ${
                  bg === "white"
                    ? "border-black hover:bg-black"
                    : "border-white hover:bg-white"
                } items-center gap ease-out duration-200 transform hover:scale-125`}
              >
                <div className="relative h-full w-full bg-gradient-to-r rounded-lg flex justify-center items-center">
                  <div
                    className={`h-4 w-4 border-2 ${
                      text === "black"
                        ? "border-black group-hover:border-white "
                        : "border-white group-hover:border-black"
                    } rounded-full bg-${bg === "white" ? "white" : "transparent"}`}
                  ></div>
                  <div
                    className={`absolute top-0 right-0 h-1 w-1 ${
                      text === "black"
                        ? "bg-black group-hover:bg-white"
                        : "bg-white group-hover:bg-black"
                    } rounded-full`}
                  ></div>
                </div>
              </li>
            </ul>

            <button
              className={`border-[1px] px-3 py-1 rounded-md text-xl font-bold text-[0.8rem] md:text-[1.5rem] hover:bg-${bgColor} hover:text-${hoverColor} ease-out duration-200 transform hover:scale-125`}
            >
              Resume
            </button>
          </header>
        </div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Header;
